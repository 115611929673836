import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  Chip,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { availableCompanyData, addToPayload } from '../../features/company';
import { CompanyResponse } from '../../types';
import { getCompanyData } from './helpers/getCompanyData';
import { apiEndpoints } from '../../config';
import ErrorMessage from '../../components/errorMessage';

const myHelper: { [key: string]: any } = {
  email: {
    required: "Email is required.",
    pattern: "Invalid Email Address."
  },
  domain: {
    required: "Domain is required..",
    pattern: "Invalid domain."
  },
  description: {
    maxLength: 'Your description cannot exceed 255 characters in length.'
  }
};

export default function DashboardSettings() {

  const [submitCompanyUpdateError, setSubmitCompanyUpdateError] = React.useState<string | boolean>(false);
  const [companyUpdated, setCompanyUpdated] = React.useState<boolean>(false);
  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });

  const handleOnSubmit = async (evt: any) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://rivum.uk.auth0.com/api/v2/',
        },
      });
      const submitAnswers = await fetch(apiEndpoints.company.updateCompanyInformation, {
        method: 'PATCH',
        body: JSON.stringify(evt),
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const response = await submitAnswers.json();
      dispatch(addToPayload(response.company));
      setData(response.company);
      setSubmitCompanyUpdateError(false);
      setCompanyUpdated(true);
      console.log(response);
    } catch (error) {
      setSubmitCompanyUpdateError('Sorry, we were unable to update company information. Please try again!');
      setCompanyUpdated(false);
    }
  };


  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useAppDispatch();
  const companyData = useAppSelector(availableCompanyData)[0];
  const isCompanyDataSet = companyData || {};
  const [data, setData] = React.useState<CompanyResponse>(isCompanyDataSet);

  React.useEffect(() => {
    if (!companyData || !Object.keys(companyData).length) {
      getCompanyData(getAccessTokenSilently)
        .then((payload) => {
          dispatch(addToPayload(payload.company));
          setData(payload.company);
        })
      .catch((err) => {
        console.error(err);
        dispatch(addToPayload({}));
        setData({});
      });
    }
  }, []);
  return (
    <div>
      <Container sx={{ boxShadow: 1, background: '#ffffff' }} maxWidth="xl">
        <Box minHeight="50vh" padding={3}>
          <Box sx={{ marginBottom: '2em' }}>
            <Typography variant="h2" component="h2" gutterBottom>Settings</Typography>
            <Typography variant="h4" component="h3">{data.company_name}</Typography>
            <Typography component="p">Update your company information.</Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="company_name"
                  defaultValue={data.company_name}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Company Name"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="company_allowed_domains"
                  defaultValue={[]}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      defaultValue={data.company_allowed_domains}
                      onChange={(_, data) => onChange(data)}
                      renderTags={(
                        value: any[],
                        getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
                      ) =>
                        value.map((option: any, index: any) => {
                          return (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Allowed Domains"
                          placeholder="Add a domain by pressing enter"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="company_email"
                  defaultValue={data.company_email}
                  rules={{
                    required: true,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="email"
                      fullWidth
                      label="Company Email"
                      error={error !== undefined}
                      helperText={error ? myHelper.email[error.type] : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="company_url"
                  defaultValue={data.company_url}
                  rules={{
                    required: true,
                    pattern:  /^(?:(?:https?):\/\/)?(?!:\/\/)(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}\.?|[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)$/i
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      fullWidth
                      label="Company domain"
                      error={error !== undefined}
                      helperText={error ? myHelper.domain[error.type] : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="company_description"
                  defaultValue={data.company_description}
                  rules={{
                    maxLength: {
                      value: 255,
                      message: "Too Many Characters"
                    },
                  }}
                  render={({ field,  fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Company Description"
                      placeholder="MultiLine with rows: 2 and rowsMax: 4"
                      multiline
                      rows={2}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? myHelper.description[error.type] : ""}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={12}>
                <Button size="medium" variant="contained" type="submit">Submit</Button>
              </Grid>
              <Grid item xs={12}>
              {submitCompanyUpdateError && <ErrorMessage message={String(submitCompanyUpdateError)} />}
              {companyUpdated && 'Company information successfully updated!'}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}