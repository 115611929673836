import * as React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div>
      <h2>Page not found!</h2>
      <p>Sorry about that! Please go back to the <Link to="/">home page</Link> and try again!</p>
    </div>
  )
}
