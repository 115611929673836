import * as React from 'react';
import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import theme from '../theme';

export default function Completed({ props }: any) {
  document.body.style.background = theme.palette.primary.main;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  React.useEffect(() => {
    document.body.style.backgroundColor = theme.palette.primary.main;
    return () => {
      document.body.style.backgroundColor = theme.palette.background.default;
    }
  });
  const onClick = () => {
    props.setCompleted(false);
  };
    return (
      <div>
        <Typography variant="h4" align='center' component="h4" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
          Congratulations, you have successfully submitted your answers!
        </Typography>
        <Typography align='center' component="div" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
          {Object.hasOwn(props.answersResponse.scoring, 'response') && 'Our recommendation:'}
          {!Object.hasOwn(props.answersResponse.scoring, 'response') && 'No recommendations are available at the moment. Sorry about that!'}
          <InfoOutlinedIcon
            sx={{
              color: theme.palette.primary.contrastText, 
              ':hover': {
                bgcolor: 'secondary.main',
                color: 'white',
              },
              marginLeft: '5px',
              fontSize: '1em',
              cursor: 'pointer',
            }}
            onClick={handleClickOpen}
          />
        </Typography>
        {Object.hasOwn(props.answersResponse.scoring, 'response') && Object.hasOwn(props.answersResponse.scoring.response, 'response_text') &&
        <span>
        <Typography align='center' component="p" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
          {props.answersResponse.scoring.response.response_text}
        </Typography>
        {props.answersResponse.scoring.response.response_cta_text && 
          <Typography align='center' pt={2}>
            <Button
              size="small"
              variant="contained"
              color='completedButton'
              href={props.answersResponse.scoring.response.response_cta_url}
              target='_blank'
              sx={{
                ':hover': {
                  bgcolor: 'secondary.main',
                  color: 'white',
                },
              }}
            >
              {props.answersResponse.scoring.response.response_cta_text}
            </Button>
          </Typography>
        }
        </span>
        }
        
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" textAlign={'center'}>
            {"Scoring"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Typography align='center' component="div" variant="body1" gutterBottom style={{ color: theme.palette.primary.dark}}>
              Below is some further information on the scoring.
            </Typography>
            <Typography align='center' component="div" variant="body1" gutterBottom style={{ color: theme.palette.primary.dark}}>
              Type: {props.answersResponse.scoring.message}
            </Typography>
            <Typography align='center' component="div" variant="body1" gutterBottom style={{ color: theme.palette.primary.dark}}>
              Category: {Object.hasOwn(props.answersResponse.scoring, 'response') && props.answersResponse.scoring.response.response_category_name} (ID {props.answersResponse.scoring.categoryId})
            </Typography>
            <Typography align='center' component="div" variant="body1" gutterBottom style={{ color: theme.palette.primary.dark}}>
              Rolling Average: {props.answersResponse.scoring.rollingAverage}
            </Typography>

            {props.answersResponse.scoring.type === 'WRA' &&
            <Typography align='center' component="div" variant="body1" gutterBottom style={{ color: theme.palette.primary.dark}}>
              Weighted Rolling Average Score: {props.answersResponse.scoring.weightedRollingAverage.toFixed(2)}
            </Typography>
            }
            {props.answersResponse.scoring.type === 'DELTA' &&
            <Typography align='center' component="div" variant="body1" gutterBottom style={{ color: theme.palette.primary.dark}}>
              Delta Score: {props.answersResponse.scoring.delta.toFixed(2)}
            </Typography>
            }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>
        <Typography align='left' mt={4}>
          <Button
            size="small"
            variant="outlined"
            color='completedButton'
            onClick={onClick}
            sx={{
              ':hover': {
                bgcolor: 'secondary.main',
                color: 'white',
              },
            }}
          >
            Go back to answers
          </Button>
        </Typography>
      </div>
    );
}