import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addToPayload, selectedAnswers } from '../features/answers';
import { hasBeenAnswered } from '../utils/findAnswer';
import { PossibleAnswers, QuestionI } from '../types';

export default function Boolean({question} : QuestionI) {
  const currentAnswers = useAppSelector(selectedAnswers);
  const [answer, setAnswer] = React.useState<string | undefined>();
  const dispatch = useAppDispatch();

  // Set default value in state.
  if (!hasBeenAnswered(currentAnswers, question.id)?.answer) {
    setAnswer(question.possible_answers[0].answer);
    dispatch(addToPayload({
      id: question.id,
      answer: question.possible_answers[0].answer,
      score: question.possible_answers.find(pa => pa.answer === question.possible_answers[0].answer)?.score,
    }));
  }

  const handleAnswer = (
    event: React.MouseEvent<HTMLElement>,
    newAnswer: string,
  ) => {
    setAnswer(newAnswer);
    dispatch(addToPayload({
      id: question.id,
      answer: newAnswer,
      score: question.possible_answers.find(pa => pa.answer === newAnswer)?.score,
    }));
  };

  return (
    <div>
      <ToggleButtonGroup
        value={hasBeenAnswered(currentAnswers, question.id)?.answer ?? question.possible_answers[0].answer}
        exclusive
        onChange={handleAnswer}
        aria-label="Please choose one of the following buttons"
      >
        <ToggleButton value={question.possible_answers[0].answer} aria-label={`answer ${question.possible_answers[0].answer}`} sx={{ minWidth: '50px'}}>
          {question.possible_answers[0].answer}
        </ToggleButton>
        <ToggleButton value={question.possible_answers[1].answer} aria-label={`answer ${question.possible_answers[1].answer}`} sx={{ minWidth: '50px'}}>
          {question.possible_answers[1].answer}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
