import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import BarIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import People from './People';
import Settings from './Settings';
import Insights from './Insights';
import Home from './Home';
import theme from '../../theme';
import Loading from '../../components/loading';
import Error from '../../components/error';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addToPayload, availableCompanyData } from '../../features/company';
import { CompanyResponse, LoadingError } from '../../types';
import { getCompanyData } from './helpers/getCompanyData';


const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function ManagementDashboard(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { getAccessTokenSilently, isLoading, user } = useAuth0();

  const [error, setError] = React.useState<LoadingError | null>(null);
  const [loading, setLoading] = React.useState(true);

  const dispatch = useAppDispatch();
  const companyData = useAppSelector(availableCompanyData)[0];
  const isCompanyDataSet = companyData || {};
  const [data, setData] = React.useState<CompanyResponse>(isCompanyDataSet);
  React.useEffect(() => {
    document.body.style.backgroundColor = theme.palette.managementPage.background;
    if (!user || !user['https://rivum.co/app_metadata.manager']) {
      setError({
        message: 'You do not have access to this page.',
        status: 401,
      });
    }
    if (!companyData || !Object.keys(companyData).length) {
      getCompanyData(getAccessTokenSilently)
        .then((payload) => {
          dispatch(addToPayload(payload.company));
          setData(payload.company);
        })
      .catch((error) => {
        dispatch(addToPayload({}));
        setData({});
        setError({
          message: 'HTTP error occurred.',
          status: error.status,
        });
      });
    }
    setLoading(false);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const location = useLocation();

  const drawer = (
    <div>
      <Toolbar>
      <Typography
            noWrap
            component={Link}
            to={'/dashboard/'}
            sx={{
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {data.company_name}
          </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          {
            name: 'Home',
            link: '',
            icon: <HomeIcon />
          },
          {
            name: 'Insights',
            link: 'insights',
            icon: <BarIcon />
          },
          {
            name: 'People',
            link: 'people',
            icon: <PeopleIcon />
          },
          {
            name: 'Settings',
            link: 'settings',
            icon: <SettingsIcon />
          }
        ].map((item, index) => {
          return <ListItem
            key={item.name}
            disablePadding
            component={Link}
            to={`/dashboard/${item.link}`}
            className={`${location.pathname === '/dashboard/' + item.link ? 'currentView' : ''}`}
          >
            <ListItemButton>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} sx={{ color: theme.palette.primary.main}}/>
            </ListItemButton>
          </ListItem>
        }
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
    <Container maxWidth="xl">
      {isLoading || loading && !error && 
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Loading />
      </Box>}
      {!loading && error && 
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Error
          message={error?.message ?? 'Something unexpected happened.'}
          status={error?.status ?? 500} 
        />
      </Box>

      }
    {!loading && (!error && data.company_name) &&
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: 'theme.palette.primary.main'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Management Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/people" element={<People />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/bla" Component={() => <div>Some page</div>} />
        </Routes>
      </Box>
    </Box>
    }
    </Container>

    </div>
  );
}
