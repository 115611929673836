import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useAuth0 } from "@auth0/auth0-react";


import { getCompanyMembersData } from './helpers/getCompanyMembers';
import Loading from '../../components/loading';
import Error from '../../components/error';
import { CompanyMembersResponse, LoadingError } from '../../types';

export default function DashboardPeople() {
  const [data, setData] = React.useState<CompanyMembersResponse[]>([]);
  const [error, setError] = React.useState<LoadingError | null>(null);
  const [loading, setLoading] = React.useState(true);

  const { getAccessTokenSilently, isLoading } = useAuth0();

  React.useEffect(() => {

    getCompanyMembersData(getAccessTokenSilently)
      .then((payload) => {
        setData(payload.companyMembers);
        setLoading(false);
      })
      .catch((error) => {
        setData([]);
        setError({
          message: 'HTTP error occurred.',
          status: error.status,
        });
      });
  }, []);
  return (
    <div>
      <Container sx={{ boxShadow: 1, background: '#ffffff' }} maxWidth="xl">
        {isLoading || loading && !error &&
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100px"
          >
            <Loading />
          </Box>
        }
        {!loading && error && 
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="30vh"
            padding={3}
          >
            <Error
              message={error?.message ?? 'Something unexpected happened.'}
              status={error?.status ?? 500} 
            />
          </Box>
        }

        <Box padding={3}>
          {!loading && (!error && data.length) &&
          <Box>
            <Typography variant="h2" component="h2" gutterBottom>People</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {
                data.map((item, index) => {
                  return <div key={item.employee_email}>
                    <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      {/* This is a really daft way of loading random images for now until we implement image uploads... */}
                      <Avatar alt={item.employee_first_name} src={`https://i.pravatar.cc/4${index}`} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${item.employee_first_name} ${item.employee_last_name}`}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item.employee_email}
                          </Typography>
                          <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Joined at: {
                            new Date(item.employee_onboard_date.toString()).toLocaleDateString(
                              'en-gb',
                              {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              }
                            )
                            }
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  { index + 1 !== data.length && <Divider variant="inset" component="li" />}
                </div>
              })
            }
            </List>
          </Box>
          }
        </Box>
      </Container>
    </div>
  );
}