import * as React from 'react';
import Box from '@mui/material/Box';

import Choice from '../components/choice';
import Scale from '../components/scale';
import MultiButton from '../components/multichoice-buttons';
import Boolean from '../components/boolean';
import { QuestionI } from '../types';


export default function ComponentDecider({ question }: QuestionI) {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {question.question_description && <p>{question.question_description}</p>}
      {question.question_type === 'choice' &&
        <Choice question={question} />
      }
      {question.question_type === 'scale' &&
        <Scale question={question} />
      }
      {question.question_type === 'multichoice' &&
        <MultiButton question={question} />
      }
      {question.question_type === 'boolean' &&
        <Boolean question={question} />
      }
    </Box>
  )
}
