import { apiEndpoints } from '../../../config';
import { CompanyMembersResponse } from "../../../types";

interface GetCompanyMembersResponse {
  message: string;
  companyMembers: CompanyMembersResponse[];
}
export const getCompanyMembersData = async (getAccessTokenSilently: any): Promise<GetCompanyMembersResponse> => {
  try {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://rivum.uk.auth0.com/api/v2/',
        // scope: 'read:posts',
      }
    });

    const request = await fetch(apiEndpoints.company.getMembers, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!request.ok) throw {
      message: 'Unsuccessful response.',
      status: request.status,
    };
    return request.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
