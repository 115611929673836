import * as React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import theme from '../theme';
import AvatarComp from '../components/avatar';

export const changeNavLinkColor = (color: string) => {
  document.querySelectorAll('.nav-link').forEach((link: any) => link.style.color = color);
};

export default function Layout() {
  const location = useLocation();

  // Check if the current location is the home route
  const isHomeRoute = location.pathname === '/' || location.pathname === '/app';
  return (
    // Might put this in a Header.tsx file eventually...
    <div>
      <AvatarComp></AvatarComp>
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
      {/* Might put this in a Footer.tsx file eventually... */}
      <Box
        component="img"
        sx={{
          // height: 233,
          width: 200,
          position: 'fixed',
          bottom: 30,
          right: 10,
          // maxHeight: { xs: 10, md: 10 },
          maxWidth: { xs: 100, md: 200 },
        }}
        alt="Rivum logo"
        src={process.env.PUBLIC_URL + '/logo.png'}
      />
    </div>
  );
}