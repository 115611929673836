import { apiEndpoints } from '../../../config';
import { CompanyResponse } from "../../../types";

interface GetCompanyResponse {
  message: string;
  company: CompanyResponse;
}

export const getCompanyData = async (getAccessTokenSilently: any): Promise<GetCompanyResponse> => {
 try {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://rivum.uk.auth0.com/api/v2/',
        // scope: 'read:posts',
      }
    });

    const request = await fetch(apiEndpoints.company.getCompany, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!request.ok) throw {
      message: 'Unsuccessful response.',
      status: request.status,
    };
    return request.json();
 } catch (error) {
    console.error(error);
    throw error;
 }
}
