import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route } from 'react-router-dom';

import ManagementDashboard from './pages/dashboard/ManagementDashboard'
import Home from './pages/Home';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import Recommendation from './pages/Recommendation';

export default function App() {

  const { isAuthenticated,  loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect()
  }

  return (
    <div>
      {isAuthenticated &&
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/app' element={<Home />} />
          <Route path='/recommendation' element={<Recommendation />} />
          <Route path="/dashboard/*" element={<ManagementDashboard />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      }
    </div>
  );
}
