import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function DashboardInsights() {
  return (
    <div>
      <Container sx={{ boxShadow: 1, background: '#ffffff' }} maxWidth="xl">
        <Box minHeight="50vh" padding={3}>
          <Box>
            <Typography variant="h2" component="h2" gutterBottom>Insights</Typography>
            <Typography component="p">This is where we can show fancy charts, diagrams etc etc.</Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
