import { apiEndpoints } from '../../config';
import { CompanyMembersResponse } from "../../types";

interface GetCompanyMembersResponse {
  message: string;
  companyMembers: CompanyMembersResponse[];
}
export const getRecommendation = async (getAccessTokenSilently: any): Promise<any> => {
  try {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://rivum.uk.auth0.com/api/v2/',
        // scope: 'read:posts',
      }
    });
    const request = await fetch(apiEndpoints.user.getUserRecommendation, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!request.ok) throw {
      message: 'Unsuccessful response.',
      status: request.status,
    };
    return request.json();
  } catch (error) {
    throw error;
  }
};
