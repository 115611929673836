import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { PossibleAnswers, QuestionI } from '../types';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addToPayload, selectedAnswers } from '../features/answers';
import { hasBeenAnswered } from '../utils/findAnswer';

export default function Choice({question} : QuestionI) {
  const currentAnswers = useAppSelector(selectedAnswers);
  const [answer, setAnswer] = React.useState<string | null>(
    hasBeenAnswered(currentAnswers, question.id)?.answer ?? question.possible_answers[0].answer
  );
  const dispatch = useAppDispatch();

    // Set default value in state.
  if (!hasBeenAnswered(currentAnswers, question.id)?.answer) {
    setAnswer(question.possible_answers[0].answer);
    dispatch(addToPayload({
      id: question.id,
      answer: question.possible_answers[0].answer,
      score: question.possible_answers.find(pa => pa.answer === question.possible_answers[0].answer)?.score,
    }));
  }

  const handleAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setAnswer(selectedValue);
    dispatch(addToPayload({
      id: question.id,
      answer: selectedValue,
      score: question.possible_answers.find(pa => pa.answer === selectedValue)?.score || 0,
    }));
  };

  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Please choose one:</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handleAnswer}
        value={answer}
        defaultValue={answer}
      >
        {question.possible_answers.map((pa: PossibleAnswers) => {
          return (
            <FormControlLabel
              checked={hasBeenAnswered(currentAnswers, question.id)?.answer === pa.answer ? true : false}
              value={pa.answer}
              key={pa.answer}
              control={<Radio required={true}/>}
              label={pa.answer}
            />
          )
        })}
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>
  );
}