import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { LoadingError } from '../types';
import theme from '../theme';

export default function ErrorMessage({ message }: LoadingError) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography component="p" gutterBottom sx={{ color: theme.palette.error.main }}>
        {message}
      </Typography>
    </Box>
  );
}
