export const isJSON = (str: string) => {
  let value = typeof str !== "string" ? JSON.stringify(str) : str;    
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
    
  return typeof value === "object" && value !== null;
};
