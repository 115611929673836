import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface CustomPalette {
    completedButton: PaletteColorOptions;
    managementPage: {
      background: string;
    };
    recommendationPage: {
      background: string;
      color: string;
    }
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    completedButton: true;
  }
}

const theme = createTheme({
  palette: {
    completedButton: {
      main: '#FFF'
    },
    managementPage: {
      background: '#efefef',
    },
    recommendationPage: {
      background: '#303C68',
      color: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#303C68',
      contrastText: '#fff'
    },
    secondary: {
      main: '#2b365d',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
