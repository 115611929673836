import React, { ReactNode} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthWrapperProps {
  children: ReactNode;
}


const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <></>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <></>;
  }

  return <>{children}</>;
};

export default AuthWrapper;