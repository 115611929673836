import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux'
// import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { store } from './app/store'
import App from './App';
import theme from './theme';
import AuthWrapper from './components/AuthWrapper';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);


root.render(
  // <React.StrictMode>
    <Auth0Provider
      domain="rivum.uk.auth0.com"
      clientId="Pv2JEoo9o786OlZHaX5Oem33im8DoReu"
      authorizationParams={{
        redirect_uri: window.location.origin + '/app',
        audience: 'https://rivum.uk.auth0.com/api/v2/',
        scope: 'email profile openid'
      }}
      cacheLocation="memory"
    >
      <AuthWrapper>
        <BrowserRouter>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <App />
            </ThemeProvider>
          </Provider>
        </BrowserRouter>
      </AuthWrapper>
    </Auth0Provider>
  // </React.StrictMode>
);
