import { StateI } from '../types';

/**
 * Check if a question has already been answered.
 * @param answers {StateI[]} - Currently answered questions.
 * @param questionId {number} - The ID of the question to find answers for.
 * @returns {StateI | undefined} The answer from redux.
 */
export const hasBeenAnswered = (answers: StateI[], questionId: number): StateI | undefined => {
  return answers.find(a => a.id === questionId);
};
