import * as React from 'react';
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";

import theme from '../theme';
import { getRecommendation } from './helpers/getRecommendation';
import Loading from '../components/loading';
import Error from '../components/error';
import { CompanyMembersResponse, LoadingError } from '../types';

export default function Recommendation() {
  document.body.style.background = theme.palette.primary.main;

  const { getAccessTokenSilently, isLoading } = useAuth0();

  const [data, setData] = React.useState<any>({});
  const [error, setError] = React.useState<LoadingError | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    document.body.style.backgroundColor = theme.palette.primary.main;
    getRecommendation(getAccessTokenSilently)
      .then((payload) => {
        if (payload.hasOwnProperty('recommendation')) {
          setData(payload.recommendation);
        } else {
          setData(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        setData([]);
        setError({
          message: 'HTTP error occurred.',
          status: error.status,
        });
        setLoading(false);
      });

    return () => {
      document.body.style.backgroundColor = theme.palette.background.default;
    }
  }, []);
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <div>
            {isLoading || loading && !error &&
              <Loading colour='#f5f6f7' />
            }
            {!loading && error &&
              <Error
                message={error?.message ?? 'Something unexpected happened.'}
                status={error?.status ?? 500} 
                colour='#f5f6f7'
              />
            }
            {!loading && !error && data &&
              <div>
                <Typography variant="h4" align='center' component="h4" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
                Your most recent recommendation
                </Typography>
                <Typography align='center' component="p" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
                  Flagged Category: {data.category_name}
                </Typography>
                <Typography align='center' component="p" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
                  {data.recommendation}
                </Typography>
                {data.recommendation_cta_text && data.recommendation_cta_url &&
                <Typography align='center' pt={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color='completedButton'
                    href={data.recommendation_cta_url}
                    target='_blank'
                    sx={{
                      ':hover': {
                        bgcolor: 'secondary.main',
                        color: 'white',
                      },
                    }}
                  >
                    {data.recommendation_cta_text}
                  </Button>
                </Typography>
                }
              </div>
            }
            {!data && !error && !loading && 
              <Typography variant="h4" align='center' component="h4" gutterBottom style={{ color: theme.palette.primary.contrastText}}>
              You do not have any recommendations. Please answer some questions and check back here!
              </Typography>
            }
          </div>
        </Box>
      </Container>
    );
}