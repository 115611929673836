/**
 * Important
 * This component is not currently used.
 * When we decide to use it again, we will need to update the state to use the new possible answer structure:
 * { answer: "bla", score: 40 }
 */
import * as React from 'react';
import { Button } from '@mui/material';

import { PossibleAnswers, QuestionI } from '../types';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addToPayload, selectedAnswers } from '../features/answers';
import { hasBeenAnswered } from '../utils/findAnswer';

interface ButtonsState {
  [key: string]: boolean;
}

export default function MultiButton({question} : QuestionI) {
  const answersSelectedInState = useAppSelector(selectedAnswers);

  const answeredQuestion = hasBeenAnswered(answersSelectedInState, question.id);
  const reduxButtonState: ButtonsState = answeredQuestion
    ? JSON.parse(answeredQuestion?.answer).buttonState
    : false;
  const reduxAnswerState: string[] = answeredQuestion
    ? JSON.parse(answeredQuestion?.answer).answers
    : false;

  const buttonsState: ButtonsState = reduxButtonState || question.possible_answers.reduce((ac,a) => ({ ...ac, [a.answer]: false }), {});
  const [answer, setAnswer] = React.useState<string[]>([]);

  const [setButtons, setButtonState] = React.useState<ButtonsState>(buttonsState);
  const currentAnswers: string[] = reduxAnswerState || answer;
  const dispatch = useAppDispatch();

  const handleChange = (a: string) => {
    const newButtonState = {
      ...buttonsState
    };
    if (!currentAnswers.includes(a)){
      currentAnswers.push(a);
      newButtonState[a] = true;
      setButtonState(newButtonState);
    } else {
      currentAnswers.splice(currentAnswers.indexOf(a), 1);
      newButtonState[a] = false;
      setButtonState(newButtonState);
    }
    setAnswer(currentAnswers);
    dispatch(addToPayload({
      id: question.id,
      answer: JSON.stringify({ answers: [...currentAnswers], buttonState: newButtonState}),
      // score still needs to be implemented here but currently not using this comp in prod.
    }));
  };
  return (
    <div>
     {question.possible_answers.map((pa: PossibleAnswers) => {
       return (
          <Button
            size="small"
            variant={setButtons[pa.answer] ? 'contained' : 'outlined'}
            sx={{ margin: '0 0 10px 10px' }}
            onClick={() => handleChange(pa.answer)}
            key={pa.answer}
          >{pa.answer}</Button>
       )
     })}
    </div>
  )

};

  