import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const initialState: any[] = [];

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    addToPayload: (state, action: PayloadAction<any[]>) => {
      state.push(...action.payload);
    },
  },
});

export const { addToPayload } = questionsSlice.actions;

export const currentQuestions = (state: RootState) => state.questions;

export default questionsSlice.reducer;
