import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { QuestionI } from '../types';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addToPayload, selectedAnswers } from '../features/answers';
import { hasBeenAnswered } from '../utils/findAnswer';

interface CreatedMarks {
  value: number;
  label: string;
}

const createMarks = (answers: string[]): CreatedMarks[] => {
  const answersLengthAfterFirstAnswer = answers.length - 1;
  return answers.map((answer, index) => ({
      // We always want the first question at value 0 (positioned left)
      // and then calculate the remaining answer positions based on 100 / remaining answers * by the current index.
      value: (100 / answersLengthAfterFirstAnswer) * index,
      label: answer,
  }));
};

export default function Scale({question} : QuestionI) {
  const currentAnswers = useAppSelector(selectedAnswers);
  const parsed = hasBeenAnswered(currentAnswers, question.id)?.answer
    ? JSON.parse(hasBeenAnswered(currentAnswers, question.id)?.answer as any).value
    : 0;
  const [answer, setAnswer] = React.useState<string | undefined>();
  const dispatch = useAppDispatch();

  // Set default value in state.
  if (!hasBeenAnswered(currentAnswers, question.id)?.answer) {
    dispatch(addToPayload({
      id: question.id,
      answer: JSON.stringify({
        value: 0,
        label: question.possible_answers[0].answer,
      }),
      score: question.possible_answers.find(pa => pa.answer === question.possible_answers[0].answer)?.score,
    }));
  }

  const handleAnswer = (event: React.ChangeEvent<HTMLInputElement>, marks: CreatedMarks[]) => {
    setAnswer((event.target as HTMLInputElement).value);
    const findAnswerByMark = marks.find(m => m.value === Number(event.target.value));
    dispatch(addToPayload({
      id: question.id,
      answer: JSON.stringify(findAnswerByMark),
      score: question.possible_answers.find(pa => pa.answer === findAnswerByMark?.label)?.score,
    }));
  };
  const possibleAnswers = question.possible_answers.map(pa => pa.answer);
  return (
    <div id="rivum-slider">
      <Box>
        <Slider
          aria-label="Custom marks"
          value={parsed}
          defaultValue={0}
          step={null}
          valueLabelDisplay="auto"
          onChange={(e) => handleAnswer(e as any, createMarks(possibleAnswers))}
          marks={createMarks(possibleAnswers)}
        />
      </Box>
    </div>
  );
};
