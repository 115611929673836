import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { LoadingError } from '../types';

export default function Error({ message, status, colour }: LoadingError) {
  const txtColour = colour ?? '#000';
  return (
    <Box sx={{color: txtColour}}>
      <Typography variant="h2" component="h2" gutterBottom>
        Error 🤦
      </Typography>
      <Typography variant="h4" component="h4">
        <strong>[HTTP Error]</strong> - {message}
      </Typography>

      {status &&
      <Typography variant="h4" component="h4">
        <strong>[HTTP Status Code]</strong> - {status}
      </Typography>
      }
    </Box>
  );
}
