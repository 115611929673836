import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useAppSelector } from '../../app/hooks';
import { availableCompanyData } from '../../features/company';

export default function Home() {
  const companyData = useAppSelector(availableCompanyData)[0];

  return (
    <div>
      <Container sx={{ boxShadow: 1, background: '#ffffff' }} maxWidth="xl">
        <Box minHeight="50vh" padding={3}>
          <Box>
            <Typography variant="h2" component="h2" gutterBottom>Hi, {companyData.company_name}</Typography>
            <Typography component="p">Welcome to your management dashboard. More things to come soon!</Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}