import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import answerReducer from '../features/answers';
import questionsReducer from '../features/questions';
import companyReducer from '../features/company';

export const store = configureStore({
  reducer: {
    answers: answerReducer,
    questions: questionsReducer,
    company: companyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
