/**
 * Probably a better way of doing this. This will do for now.
 */
export const apiEndpoints = {
  getQuestions: <string> process.env.REACT_APP_RIVUM_GET_QUESTIONS_URL, 
  submitAnswers: <string> process.env.REACT_APP_RIVUM_SUBMIT_ANSWERS_URL,
  company: {
    getMembers: <string> process.env.REACT_APP_RIVUM_COMPANY_URL + '/api/getCompanyMembers',
    getCompany: <string> process.env.REACT_APP_RIVUM_COMPANY_URL + '/api/getCompany',
    updateCompanyInformation: <string> process.env.REACT_APP_RIVUM_COMPANY_URL + '/api/updateCompanyInformation',
  },
  user: {
    getUserRecommendation: <string> process.env.REACT_APP_RIVUM_USER_SERVICE_URL + '/api/getUserRecommendation',
  }
};
