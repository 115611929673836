import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';

import theme from '../theme';

const AvatarComp = () => {
  const { user, logout } = useAuth0();

  const [avatarEl, setAvatarEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [invisible, setInvisible] = React.useState(false);

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
  };

  const open = Boolean(avatarEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ position: "fixed", top: 10, right: 20, zIndex: 2000 }}>
      <Stack direction="row" spacing={1}>
        <Button aria-describedby={id} onClick={handleAvatarClick}>
          <Avatar src={user?.picture} alt={user?.name} />
          <KeyboardArrowDownIcon />
        </Button>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={`Hi, ${user?.name}`} style={{ color: theme.palette.primary.main }} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding component={Link} to={'/'}>
            <ListItemButton>
              <ListItemText primary='Home' style={{ color: theme.palette.primary.main }} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding component={Link} to={'/recommendation'}>
            <ListItemButton>
              <ListItemText primary='Recommendation' style={{ color: theme.palette.primary.main }} />
            </ListItemButton>
          </ListItem>
          <Divider />
          {user && user['https://rivum.co/app_metadata.manager'] &&
            <React.Fragment>
              <ListItem disablePadding>
                <ListItemButton>
                  <Link to="/dashboard" className='nav-link' style={{ color: theme.palette.primary.main }}>Management Dashboard</Link>
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          }
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Log out" style={{ color: theme.palette.primary.main }} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}/>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default AvatarComp;
