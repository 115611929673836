import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface LoadingMessage {
  message?: string;
  colour?: string;
}
export default function Loading({ message, colour }: LoadingMessage ) {
  const txtColour = colour ?? '#000';
  return (
    <Box>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress sx={{color: txtColour}} />
      </div>
      {message &&
      <Typography variant="h4" component="h2" gutterBottom sx={{textAlign: 'center', color: txtColour}}>
        {message}
      </Typography>
      }
    </Box>
  );
}
