import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { StateI } from '../types';

const initialState: StateI[] = [];

export const answerSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addToPayload: (state, action: PayloadAction<StateI>) => {
      const findItem = state.some(a => a.id === action.payload.id);
      // Remove item if ID already exists.
      // There may be a better way of mutating the state but 🤷.
      if (findItem) {
        state.splice(state.findIndex(a => a.id === action.payload.id), 1);
      }
      state.push(action.payload);
    },
  },
});

export const { addToPayload } = answerSlice.actions;

export const selectedAnswers = (state: RootState) => state.answers;

export default answerSlice.reducer;
