import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper, Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import { useAuth0 } from "@auth0/auth0-react";

import '../style/app.css';
import { FormattedAnswers, LoadingError } from '../types';
import Loading from '../components/loading';
import Error from '../components/error';
import ComponentDecider from '../utils/componentDecider';
import Completed from '../components/completed';
import { store } from '../app/store';
import { isJSON } from '../utils/isJSON';
import { apiEndpoints } from '../config';
import ErrorMessage from '../components/errorMessage';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addToPayload, currentQuestions } from '../features/questions';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<LoadingError | null>(null);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(false);
  const [answersResponse, setAnswersResponse] = React.useState(false);
  const [getSubmitBtnDisabledState, setSubmitBtnDisabledState] = React.useState(false);
  const [submitAnswersError, setSubmitAnswersError] = React.useState<string | boolean>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const currentQs = useAppSelector(currentQuestions);

  // I don't like these promise chains...
  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.default;
    if (currentQs.length) {
      // Set loading to false if we already have questions in state.
      setLoading(false);
    } else {
      // If no questions in state, request from backend.
      getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://rivum.uk.auth0.com/api/v2/',
          // scope: 'read:posts',
        }
      }).then((token) => {
        fetch(apiEndpoints.getQuestions, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              // NOTE: This doesn't work as expected.
              // This setting of error should be passed to component but isn't playing nice...
              setError({
                message: 'HTTP error occurred.',
                status: response.status,
              });
              return;
            } else {
              return response.json();
            }
          })
          .then((actualData) => {
            if (!actualData.questions.length) {
              setError({
                message: 'No questions available.',
                status: 404,
              });
              return;
            }
            dispatch(addToPayload(actualData.questions));
            setError(null);
          })
          .catch((err) => {
            setError({
              message: err.message,
              status: 500,
            });
            dispatch(addToPayload([]));
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        // probably want to do something a bit better here...
        setError({
          message: `Unable to load resource. Please log out and try again.`,
          status: 403,
        });
        dispatch(addToPayload([]));
      })
    }
  }, []);
  // const maxSteps = !loading ? data.length : 0;
  const maxSteps = !loading && currentQs ? currentQs.length : 0;

  const onClick = async () => {
    const answeredQuestionsState = store.getState();
    const formatted = answeredQuestionsState.answers.map((aq) => {
      const answer = isJSON(aq.answer) ? JSON.parse(aq.answer).label as any : aq.answer;
      return {
        questionId: aq.id,
        answer,
        score: aq.score
      }
    }) as FormattedAnswers[];

   try {
    setSubmitBtnDisabledState(true);
    setSubmitAnswersError(false);
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://rivum.uk.auth0.com/api/v2/',
        // scope: 'read:posts',
      },
    });
    const submitAnswers = await fetch(apiEndpoints.submitAnswers, {
      method: 'POST',
      body: JSON.stringify(formatted),
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (submitAnswers.status !== 201) throw `Request failed. Service responded with a HTTP status of ${submitAnswers.status}.`;
    const response = await submitAnswers.json();
    setAnswersResponse(response);
    setCompleted(true);
   } catch (error) {
    console.error(error);
    setSubmitBtnDisabledState(false);
    setSubmitAnswersError('Unable to submit your answers. Please try again!');
   }
  }
  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        {isLoading || loading && !error && <Loading message="Give us a sec we're just loading your questions!" />}
        {/* NOTE: This doesn't work as expected. */}
        {/* !response.ok is not passing error so I have to make defaults to not break app. */}
        {!loading && (!currentQs || error) && 
          <Error
            message={error?.message ?? 'Something unexpected happened.'}
            status={error?.status ?? 500} 
          />
        }
        {completed && <Completed props={{setCompleted, answersResponse}} />}
        {!completed &&
        <div>
        {process.env.NODE_ENV === 'development' &&
        <div id="state-debugger">
          <h2>State Debugger</h2>
          <pre>{JSON.stringify(store.getState(), null, 2)}</pre>
        </div>
        }
        {!loading && !error && currentQs && 
        <Box sx={{ minHeight: 500}}>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 2,
              bgcolor: 'background.default',
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>{currentQs[activeStep].question_title}</Typography>
          </Paper>
          <ComponentDecider question={currentQs[activeStep]} />
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
          {activeStep === currentQs.length - 1 &&
            <Button
              size="small"
              variant="contained"
              disabled={getSubmitBtnDisabledState}
              style={{margin: '0 auto', display: "flex"}}
              onClick={onClick}
            >
              Submit Answers
            </Button>
            }
        </Box>}
        {submitAnswersError && <ErrorMessage message={String(submitAnswersError)} />}
        </div>}
      </Box>
    </Container>
  );
}
